import { FaqQuestion } from '@/components';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Faq",
  components: { FaqQuestion },
  data: () => ({}),
  computed:{
    ...mapGetters("machine", {
      faq: 'getFaq',
      categories: "getCategories",
    })
  },
  methods:{
    ...mapActions("machine", {
      fetchFaq: "FETCH_FAQ"
    }),
  },
  created(){
    this.fetchFaq();
  }
};
