<template>
	<div class="o-row o-row--lg">
		<div class="o-container">
			<div class="c-faq">
				<h2>Veelgestelde vragen</h2>
				<FaqQuestion v-for="question in faq" :key="question" :question="question" />
				<div class="c-faq__footer">
					<h2>Heb je nog steeds een vraag?</h2>
					<p>
						Als u het antwoord op uw vraag niet in onze FAQ vindt, neem dan gerust contact met ons op.
						<br />
						Wij zullen u spoedig antwoorden!
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Faq.controller.js"></script>
<style lang="scss" src="./Faq.styles.scss"></style>
